import React from 'react';
import { Box, Flex, Text, Link as ChakraLink, useColorModeValue, Icon, Link } from '@chakra-ui/react';
import { FaFacebook, FaInstagram, FaYoutube } from 'react-icons/fa';
import Logo from '../../assets/images/folci_logo.png';
const Footer = () => {
  const bgColor = useColorModeValue('gray.700', 'gray.800');
  const textColor = useColorModeValue('white', 'gray.200');
  const zoomBgColor = useColorModeValue('white', 'blue.600');
  const zoomHoverBgColor = useColorModeValue('blue.600', 'blue.700');
  const zoomTextColor = useColorModeValue('gray.700', 'gray.800');

  return (
    <Box bg={bgColor} color={textColor} p={10}>
      <Flex direction={{ base: 'column', md: 'row' }} justify="space-between" align="center">
        <Flex direction="column" align="center" mb={{ base: 4, md: 0 }}>
          <Box boxSize="60px">
            <img src={Logo} alt="Fountain of Life Church Logo" />
          </Box>
          <Text mt={2}>Fountain of Life Church Int'l</Text>
        </Flex>
        <Flex>
          <ChakraLink mx={2} href="/" _hover={{ textDecoration: 'underline' }}>Home</ChakraLink>
          <ChakraLink mx={2} href="/about" _hover={{ textDecoration: 'underline' }}>About Us</ChakraLink>
          <ChakraLink mx={2} href="/contact" _hover={{ textDecoration: 'underline' }}>Contact</ChakraLink>
        </Flex>
        <Flex align="center">
          <Text>Follow us:</Text>
          <ChakraLink mx={2} href="https://www.facebook.com/fountainoflifechurchintl" aria-label="Facebook" _hover={{ color: 'blue.500' }}>
            <Icon as={FaFacebook} />
          </ChakraLink>
          <ChakraLink mx={2} href="https://www.instagram.com/folciuk/" aria-label="Instagram" _hover={{ color: 'pink.400' }}>
            <Icon as={FaInstagram} />
          </ChakraLink>
          <ChakraLink href="https://us02web.zoom.us/j/81148930003?pwd=aFFrV0VEam9Fam1ZZ1l6MHdQUHpwUT09#success" isExternal fontWeight="bold" _hover={{ bg: zoomHoverBgColor }} bg={zoomBgColor} color={zoomTextColor} fontSize="sm" p={0.5} borderRadius="md" mx={2}>
            Zoom
          </ChakraLink>
          <ChakraLink mx={2} href="https://www.youtube.com/@folci9202/streams" aria-label="YouTube" _hover={{ color: 'red.600' }}>
            <Icon as={FaYoutube} />
          </ChakraLink>
        </Flex>
      </Flex>
      <Text textAlign="center" mt={4} fontSize="sm">
        © {new Date().getFullYear()} Fountain of Life Church Int'l. All rights reserved.
        <Link href='https://www.instagram.com/bbusari01/?hl=en-gb'> Website Designed By Boladale.</Link>
      </Text>
    </Box>
  );
};

export default Footer;
