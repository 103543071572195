import { Box } from '@chakra-ui/react';
import Navbar from '../Navbar';
import { Outlet } from 'react-router-dom';
import Footer from '../Footer';

const Layout = () => {
  return (
    <Box padding="4" margin="4">
      <Navbar />
      <Outlet />
      <Footer />
    </Box>
  );
};

export default Layout;
