import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  colors: {
    primary: '#2B6CB0', // Adjusted primary color to a shade of blue
    secondary: '#F6E05E', // Gold color for accents and highlights
    background: '#EDF2F7', // Light blue/grey background for contrast with primary
  },
  fonts: {
    heading: "'Helvetica Neue', sans-serif",
    body: "'Helvetica Neue', sans-serif",
  },
  components: {
    Link: {
      baseStyle: {
        _hover: {
          textDecoration: 'none',
          color: 'secondary',
        },
      },
    },
    Button: {
      variants: {
        solid: (props) => ({
          bg: props.colorMode === 'dark' ? 'primary' : 'secondary',
          color: props.colorMode === 'dark' ? 'white' : 'gray.800',
        }),
      },
    },
  },
  styles: {
    global: {
      'html': {
        fontSize: '100%',
      },
      'body': {
        margin: 0,
        padding: 0,
        font: "300 1.1rem 'Helvetica Neue', sans-serif",
        color: 'gray.600',
        bg: 'background',
        fontFamily: "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
      },
      'input, textarea': {
        fontFamily: "'Helvetica Neue', sans-serif",
      },
      'a': {
        color: 'primary',
        _hover: {
          textDecoration: 'underline',
        },
      },
    },
  },
});

export default theme;
