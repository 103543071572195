import React from 'react';
import { Box, Button, Text, VStack } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

const ErrorPage = () => {
  return (
    <VStack spacing={4} justifyContent="center" alignItems="center" height="100vh">
      <Box textAlign="center">
        <Text fontSize="5xl" fontWeight="bold" color="red.500">404</Text>
        <Text fontSize="xl" mt={3}>Oops! Page not found.</Text>
        <Text mt={2}>We can't find the page you're looking for.</Text>
        <Button mt={4} colorScheme="blue" as={Link} to="/">Go Home</Button>
      </Box>
    </VStack>
  );
};

export default ErrorPage;
