import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, FormControl, FormLabel, Input, Button, VStack, useToast } from '@chakra-ui/react';
import { useAuth } from '../../context/authContext';
import { doSignInWithEmailAndPassword } from '../../firebase/auth';

const Login = () => {
    const { userLoggedIn } = useAuth();
    const toast = useToast();
    const navigate = useNavigate(); // Hook for programmatic navigation
  
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    
    useEffect(() => {
      if (userLoggedIn) {
        navigate("/admin"); // Use navigate function from useNavigate hook for redirection
      }
    }, [userLoggedIn, navigate]); // Dependency array includes userLoggedIn to react on its change
  
    const onSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
          await doSignInWithEmailAndPassword(email, password);
          toast({
            title: 'Logged in successfully.',
            description: "You've been logged into your account.",
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
          navigate("/admin"); 
        } catch (error) {
          toast({
            title: 'Login failed.',
            description: error.message,
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        } finally {
          setIsLoading(false);
        }
      };

  return (
    <VStack spacing={4} align="stretch">
      <Box maxW="sm" mx="auto" mt="10" p="8" borderWidth="1px" borderRadius="lg" boxShadow="lg">
        <form onSubmit={onSubmit}>
          <VStack spacing={6}>
            <FormControl id="email" isRequired>
              <FormLabel>Email address</FormLabel>
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
              />
            </FormControl>
            <FormControl id="password" isRequired>
              <FormLabel>Password</FormLabel>
              <Input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter your password"
              />
            </FormControl>
            <Button
              isLoading={isLoading}
              type="submit"
              colorScheme="blue"
              width="full"
              loadingText="Signing In"
            >
              Log In
            </Button>
          </VStack>
        </form>
      </Box>
    </VStack>
  );
};

export default Login;
