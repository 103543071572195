import React, { useState, useEffect } from 'react';
import { Box } from '@chakra-ui/react';

const YouTubePlayer = () => {
  const [videoId, setVideoId] = useState('');

  useEffect(() => {
    const fetchLatestVideo = async () => {
      const apiKey = 'AIzaSyAhpWQaMmW5o4jyg6LJ5QI-iZ_cvI_kSrY';
    const channelId = 'UCPj8cVQG3wLJv1dZPsLjh4A';
      const apiUrl = `https://www.googleapis.com/youtube/v3/search?key=${apiKey}&channelId=${channelId}&order=date&part=snippet&type=video`;

      try {
        const response = await fetch(apiUrl);
        const data = await response.json();
        if (data.items && data.items.length > 0) {
          const latestVideoId = data.items[0].id.videoId;
          setVideoId(latestVideoId);
        }
      } catch (error) {
        console.error('Failed to fetch latest video:', error);
      }
    };

    fetchLatestVideo();
  }, []);

  // Conditional rendering based on videoId
  return videoId ? (
    <Box display="flex" justifyContent="center" alignItems="center" height="auto">
      <iframe
        width="560"
        height="315"
        src={`https://www.youtube.com/embed/${videoId}`}
        title="Latest Video"
        frameBorder="0"
        allowFullScreen
      ></iframe>
    </Box>
  ) : null; // Return null or a placeholder if videoId is not set
};

export default YouTubePlayer;
