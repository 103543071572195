import { React, useEffect } from 'react';
import { Box, VStack, HStack, Text, Image, useClipboard, Container, IconButton } from '@chakra-ui/react';
import pastorHenry from './pastorHenry.jpg';
import pastorSteve from './pastorSteve.jpg';
import pastorEddie from './pastorEddie.jpg';
import { FaCopy } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';

const About = () => {

    const location = useLocation();

  useEffect(() => {
    if (location.state?.scrollToSection) {
      const section = document.getElementById(location.state.scrollToSection);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);


    const accName = "Fountain of life church Int'l";
    const sortCode = "20-53-00";
    const accNum = "60846732";
    const { hasCopied: hasCopiedAccName, onCopy: onCopyAccName } = useClipboard(accName);
    const { hasCopied: hasCopiedSortCode, onCopy: onCopySortCode } = useClipboard(sortCode);
    const { hasCopied: hasCopiedAccNum, onCopy: onCopyAccNum } = useClipboard(accNum);

    return (
        <Container maxW="container.xl" py={10}>
            <VStack spacing={10} align="stretch">
                <VisionSection />
                <TeamSection />
                <GospelSection />
                <DonationsSection             
                    id="donations"
                    accName={accName} onCopyAccName={onCopyAccName} hasCopiedAccName={hasCopiedAccName}
                    sortCode={sortCode} onCopySortCode={onCopySortCode} hasCopiedSortCode={hasCopiedSortCode}
                    accNum={accNum} onCopyAccNum={onCopyAccNum} hasCopiedAccNum={hasCopiedAccNum}
                />
            </VStack>
        </Container>
    );
};

const VisionSection = () => (
    <Box bg="gray.100" p={8} borderRadius="lg" boxShadow="md">
        <Text fontSize="2xl" fontWeight="bold" mb={4} >Our Vision for the Community</Text>
        <VStack align="start" spacing={3}>
            <Text>Health and Prosperity to the community</Text>
            <Text>Reduction of crime and criminal activities</Text>
            <Text>Growth and thriving local businesses & families</Text>
        </VStack>
    </Box>
);

const TeamSection = () => (
    <VStack spacing={5}>
        <Text fontSize="2xl" fontWeight="bold" >Meet the Pastoral Team</Text>
        <HStack spacing={5} wrap="wrap" justify="center">
            <TeamMember name="Founder: Rev. Henry Nsiah Anning" image={pastorHenry} />
            <TeamMember name="Senior Pastor: Rev. Stephen Anning" image={pastorSteve} />
            <TeamMember name="Residential Pastor: Evang. Edward Anning" image={pastorEddie} />
        </HStack>
    </VStack>
);

const TeamMember = ({ name, image }) => (
    <VStack bg="white" p={4} borderRadius="lg" boxShadow="lg" _hover={{ boxShadow: "xl" }} transition="box-shadow 0.3s" maxWidth="220px" textAlign="center">
        <Image boxSize="120px" borderRadius="full" src={image} alt={name} mb={2} />
        <Text fontWeight="bold">{name}</Text>
    </VStack>
);

const DonationsSection = ({ accName, onCopyAccName, hasCopiedAccName, sortCode, onCopySortCode, hasCopiedSortCode, accNum, onCopyAccNum, hasCopiedAccNum, id }) => (
    <Box id={id} bg="blue.50" p={8} borderRadius="lg" boxShadow="md">
        <Text fontSize="2xl" fontWeight="bold" mb={4}>Donations</Text>
        <Text>To Fountain of life church Int'l:</Text>
        <Text>Barclays Bank</Text>
        <HStack spacing={2} align="center">
            <Text>{accName}</Text>
            <IconButton icon={<FaCopy />} size="sm" onClick={onCopyAccName} aria-label="Copy account name" isRound />
            {hasCopiedAccName && <Text color="green.500">Copied!</Text>}
        </HStack>
        <HStack spacing={2} align="center">
            <Text>Sort code: {sortCode}</Text>
            <IconButton icon={<FaCopy />} size="sm" onClick={onCopySortCode} aria-label="Copy sort code" isRound />
            {hasCopiedSortCode && <Text color="green.500">Copied!</Text>}
        </HStack>
        <HStack spacing={2} align="center">
            <Text>Account no: {accNum}</Text>
            <IconButton icon={<FaCopy />} size="sm" onClick={onCopyAccNum} aria-label="Copy account number" isRound />
            {hasCopiedAccNum && <Text color="green.500">Copied!</Text>}
        </HStack>
    </Box>
);
const GospelSection = () => (
    <Box bg="white" p={8} borderRadius="lg" boxShadow="md" textAlign="center">
        <Text fontSize="2xl" fontWeight="bold" mb={4}>The GOSPEL</Text>
        <Text mb={4}>
            To the reader, know that God loves you! Out of God's love, He created every man, woman, and child on earth to fulfill a unique purpose. You cannot take advantage of what God has for you unless you know Him. God already knows you and wants you to acknowledge Him so you can enter into a relationship with Him. God so loved you that He sent His son Jesus to die for your sins so that you can have the forgiveness of sins and eternal life.
        </Text>
        <Text mb={4}>
            Today you can become a child of God free from shame, guilt, oppression, and with all your sins forgiven. All you need to do is to ask God.
        </Text>
        <Text fontStyle="italic" mb={4}>
            Say This Prayer: "God, I ask you to be my father and take me as your child. I believe you sent Jesus Christ to die on the cross, who rose again so that I can be forgiven of all my sins. From today, be my Lord, my savior, and live in me. Amen"
        </Text>
    </Box>
);


export default About;
