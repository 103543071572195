import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Box, Flex, Image, IconButton, Drawer, DrawerBody, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton, Link as ChakraLink, Button, useColorModeValue, useDisclosure, useToast, Text } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import Logo from '../../assets/images/folci_logo.png';
import { HamburgerIcon } from '@chakra-ui/icons';
import { useAuth } from '../../context/authContext'; // Adjust the import path as needed
import { doSignOut } from '../../firebase/auth'; // Adjust the import path as needed


const Navbar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [placement] = useState('right');
  const { userLoggedIn } = useAuth(); // Use useAuth to check if user is logged in
  const navigate = useNavigate(); // For navigation after logout
  const bgColor = useColorModeValue('white', 'gray.800');
  const color = useColorModeValue('black', 'white');
  const hoverBgColor = useColorModeValue('gray.200', 'gray.700');
  const [navigateTo, setNavigateTo] = useState(null);

  const toast = useToast();
  const { setCurrentUser } = useAuth(); // Assuming your context provides this

  console.log("User Logged In:", userLoggedIn);

  useEffect(() => {
    if (!isOpen && navigateTo) {
      navigate(navigateTo.path, navigateTo.options);
      setNavigateTo(null); // Reset navigateTo state
    }
  }, [isOpen, navigateTo, navigate]);

  const handleNavigateAndScroll = (path, sectionId) => {
    setNavigateTo({ path, options: { state: { scrollToSection: sectionId } } }); 
    onClose(); 
  };


  const handleLogout = async () => {
    try {
      await doSignOut();
      if (setCurrentUser) {
        setCurrentUser(null); // Ensure this effectively clears the user state
      }
      navigate('/');
      toast({
        title: 'Logged out successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Logout failed.',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };


  

  return (
    <Flex
      align="center"
      justify="space-between"
      bg={bgColor}
      color={color}
      boxShadow="0 2px 4px rgba(0, 0, 0, 0.1)"
      p={2}
      position="relative"
      m="auto"
      w="full"
    >
      <ChakraLink as={Link} to="/" display="flex" alignItems="center" _hover={{ textDecoration: 'none' }}>
        <Image
          src={Logo}
          alt="logo"
          boxSize={['50px', '60px', '70px', '80px']}
          objectFit="cover"
          mr={2}
        />
        {userLoggedIn ? ( <Text>Welcome FOLCI ADMIN</Text> ):( <Text>Fountain of Life Church Int'l</Text>)}
      </ChakraLink>

      <IconButton
        aria-label="Open Menu"
        size="lg"
        mr={2}
        bg="blue.600"
        icon={<HamburgerIcon />}
        display={{ md: 'none' }}
        onClick={onOpen}
      />

      <Box display={{ base: 'none', md: 'flex' }}>
        {['/', '/About', '/Contact', '/Streams'].map((path, index) => (
          <ChakraLink
            as={NavLink}
            to={path}
            px={4}
            py={2}
            rounded="md"
            _hover={{ bg: hoverBgColor }}
            key={index}
          >
            {path === '/' ? 'Home' : path.substring(1)}
          </ChakraLink>
        ))}
        <Button
        mr={4}
        colorScheme="teal"
        onClick={() => handleNavigateAndScroll('/about', 'donations')}
        >Donate Now</Button>
        {userLoggedIn ? (
          <>
            <ChakraLink as={NavLink} to="/admin" px={4} py={2} rounded="md" _hover={{ bg: hoverBgColor }}>
              Admin
            </ChakraLink>
            <Button onClick={handleLogout} px={4} py={2} rounded="md" bg="red.500" color="white" _hover={{ bg: "red.600" }}>
              Logout
            </Button>
          </>
        ) : (
          <ChakraLink as={Link} to="/admin" px={4} py={2} rounded="md" bg="blue.500" color="white" _hover={{ bg: "blue.600" }}>
            Login
          </ChakraLink>
        )}
      </Box>

      <Drawer placement={placement} onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">Navigation</DrawerHeader>
          <DrawerBody>
          {['/', '/About', '/Contact', '/Streams'].map((path, index) => (
              <ChakraLink
                as={NavLink}
                to={path}
                display="block"
                py={2}
                _hover={{ bg: hoverBgColor }}
                key={index}
                onClick={onClose} // Close the drawer when a link is clicked.
              >
                {path === '/' ? 'Home' : path.substring(1)}
              </ChakraLink>
            ))}
            <Button
              colorScheme="teal"
              onClick={() => handleNavigateAndScroll('/about', 'donations')}
              mt={2} 
            >
              Donate Now
            </Button>
            {/* Conditionally render Admin and Logout options in the mobile drawer */}
            {userLoggedIn ? (
              <>
                <ChakraLink
                  as={NavLink}
                  to="/admin"
                  display="block"
                  py={2}
                  _hover={{ bg: hoverBgColor }}
                  onClick={onClose}
                >
                  Admin
                </ChakraLink>
                <Button
                  onClick={handleLogout}
                  display="block"
                  width="full"
                  mt={2}
                  bg="red.500"
                  color="white"
                  _hover={{ bg: "red.600" }}
                >
                  Logout
                </Button>
              </>
            ) : (
              <ChakraLink
                as={Link}
                to="/login"
                display="block"
                py={2}
                mt={2}
                bg="blue.500"
                color="white"
                textAlign="center"
                rounded="md"
                _hover={{ bg: "blue.600" }}
                onClick={onClose}
              >
                Login
              </ChakraLink>
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
};

export default Navbar;