// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDibPtUiGoIAQviZhE907DS7L78Vz35oLM",
  authDomain: "folci-website.firebaseapp.com",
  projectId: "folci-website",
  storageBucket: "folci-website.appspot.com",
  messagingSenderId: "248989649669",
  appId: "1:248989649669:web:f032c81224407c0ffe456c",
  measurementId: "G-VG6M1RJB4B"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app); 
const storage = getStorage(app);

export { app, auth, db, storage };