import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth } from '../../firebase/firebase'; // Ensure this path is correct

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  // Add userLoggedIn state to track if a user is logged in
  const [userLoggedIn, setUserLoggedIn] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user);
      // Update userLoggedIn based on the user's presence
      setUserLoggedIn(!!user); // !! converts truthy/falsy value to boolean
      setLoading(false);
    });

    return unsubscribe; // Unsubscribe on cleanup
  }, []);

  const value = {
    currentUser,
    userLoggedIn, // Make sure to provide this
    // Other context values like methods for login, signup, logout
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
