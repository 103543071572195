import React from 'react';
import { Box, VStack, Text, Link as ChakraLink, Container, AspectRatio } from '@chakra-ui/react';

const Contact = () => {
  return (
    <Container maxW="container.xl" py={10}>
      <VStack spacing={8} align="stretch">
        <Box bg="white" boxShadow="xl" p={8} borderRadius="lg">
          <Text as="h1" fontSize="3xl" color="blue.600" fontWeight="bold" mb={4}>Get In Touch</Text>
          <Text as="h2" fontSize="2xl" color="blue.500">Senior Pastor: Stephan Anning</Text>
          <VStack align="start" mt={4}>
            <Text fontSize="lg">Address: <ChakraLink href="https://maps.google.com/maps?q=248+London+Rd,+Romford+RM7+9EL" isExternal color="blue.400">248 London Rd, Romford RM7 9EL</ChakraLink></Text>
            <Text fontSize="lg">OFFICE: <ChakraLink href="tel:02085940677" color="blue.400">0208-594 0677</ChakraLink></Text>
            <Text fontSize="lg">Email: <ChakraLink href="mailto:info@folci.org" color="blue.400">info@folci.org</ChakraLink></Text>
          </VStack>
        </Box>

        <Box boxShadow="xl" borderRadius="lg" overflow="hidden">
          <AspectRatio ratio={16 / 9}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2479.6608632176126!2d0.16356851300715283!3d51.5744503717111!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8a66cb1967fd3%3A0x98e27e1f480af9ed!2sFountain%20of%20life%20church%20Int'l-%20(Uk)!5e0!3m2!1sen!2suk!4v1692554059994!5m2!1sen!2suk"
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="google map"
            ></iframe>
          </AspectRatio>
        </Box>
      </VStack>
    </Container>
  );
};

export default Contact;
