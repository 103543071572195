import React from 'react';
import { Box, VStack, HStack, Text, Image, Link as ChakraLink, Container } from '@chakra-ui/react';
import yeartheme from './yeartheme2024.JPG';
import biblestudy from './biblestudy.png';
import bibleholding from './bibleholding.jpeg';
import blackfamilypraying from './black-family-praying.jpg';
import YouTubePlayer from './YouTubePlayer';
import { db } from '../../firebase/firebase'; // Ensure this path is correct
import { collection, orderBy, query, getDocs, where, limit } from 'firebase/firestore';
import { useState, useEffect } from 'react';

const Home = () => {

  return (
    <Container maxW="container.xl" py={10}>
      <WelcomeSection />
      <UpcomingEventsSection />
      <OurServicesSection />
      <RecentServiceSection />
    </Container>
  );
};

const WelcomeSection = () => (
  <Box bg="white" p={8} boxShadow="xl" borderRadius="lg" mb={5}>
    <HStack spacing={10} align="center" justify="center" wrap="wrap-reverse">
      <Image src={yeartheme} alt="theme" boxSize={{ base: "90%", md: "300px" }} objectFit="cover" borderRadius="full" />
      <VStack align="start" maxW="600px">
        <Text as="h1" fontSize="3xl" fontWeight="bold" color="blue.600">Welcome to FOLCI (Fountain Of Life Church Int'l)</Text>
        <Text fontSize="lg" color="gray.600">
          We are a church based in Romford, Essex, RM7 9EL, UK. Fountain of Life Church is a God-given vision to reach out to all nations with the gospel of our Lord Jesus Christ in an atmosphere of warmth, love, and excitement. We believe that the power of God through the gospel will give hope to the hopeless, freedom to those in captivity, healing to the hurting, joy, and peace to the troubled.
        </Text>
      </VStack>
    </HStack>
  </Box>
);

const UpcomingEventsSection = () => {
  const [eventsToShow, setEventsToShow] = useState([]);

  useEffect(() => {
    const fetchEventsToShowInHome = async () => {
      // Limit the number of events fetched to 3
      const q = query(collection(db, 'events'), where('showInHome', '==', true), orderBy('createdAt', 'desc'), limit(3));
      const querySnapshot = await getDocs(q);
  
      const fetchedEvents = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setEventsToShow(fetchedEvents);
    };
  
    fetchEventsToShowInHome();
  }, []);

  return (
    <Box bg="blue.500" p={[4, 8]} boxShadow="xl" borderRadius="lg" color="white" mb={5}>
  <VStack spacing={4}>
    {eventsToShow.length > 0 ? (
      eventsToShow.map(event => (
        <VStack key={event.id} spacing={4} align="stretch">
          <Text as="h1" fontSize={["xl", "2xl"]} fontWeight="bold">{event.title}</Text>
          <Text fontSize={["sm", "md"]}>{event.message}</Text>
          {event.imageUrl && (
            <Image src={event.imageUrl} alt={event.title} boxSize={["200px", "250px"]} objectFit="cover" />
          )}
        </VStack>
      ))
    ) : (
      <Text>No upcoming events to display</Text>
    )}
  </VStack>
</Box>
  );
};



const OurServicesSection = () => (
  <VStack spacing={5} p={8} bg="white" color="gray.600" boxShadow="xl" borderRadius="lg" mb={5}>
    <Text as="h1" fontSize="3xl" fontWeight="bold" color="blue.600" textAlign="center">Our Services</Text>
    <HStack spacing={4} wrap="wrap" justify="center">
      <ServiceCard title="Wednesday Services on Zoom" imageSrc={biblestudy} text="WEDNESDAYS we have our Bible Study on zoom from 8pm to 9pm" link="https://us02web.zoom.us/j/81148930003?pwd=aFFrV0VEam9Fam1ZZ1l6MHdQUHpwUT09#success" />
      <ServiceCard title="Friday Services" imageSrc={bibleholding} text="First Friday of the Month we have our prayer and deliverance service from 7:30pm-9pm. Last Friday of each Month is our Half-night from 8pm to 11pm." />
      <ServiceCard title="Sunday Family Service" imageSrc={blackfamilypraying} text="SUNDAYS we have our family services 10am -11:30am. The first Sunday of every month is our Communion service from 10am to 12pm. This will be next Sunday." link="https://www.youtube.com/@folci" />
    </HStack>
  </VStack>
);

const RecentServiceSection = () => (
  <Box bg="blue.500" color="white" p={8} boxShadow="xl" borderRadius="lg">
    <Text as="h1" fontSize="2xl" fontWeight="bold" textAlign="center" mb={4}>Watch Our Recent Service</Text>
    <YouTubePlayer />
  </Box>
);

  
const ServiceCard = ({ title, imageSrc, text, link }) => (
  <VStack bg="blue.100" p={4} boxShadow="md" borderRadius="md" maxW="200px" textAlign="center" _hover={{ boxShadow: "lg", transform: "translateY(-2px)" }} transition="all 0.3s">
    <Image src={imageSrc} alt={title} boxSize="100px" objectFit="cover" borderRadius="full" />
    <Text as="h2" fontSize="xl" fontWeight="bold">{title}</Text>
    <Text fontSize="md">{text}</Text>
    {link && <ChakraLink href={link} isExternal color="blue.600" fontWeight="bold">Learn More</ChakraLink>}
  </VStack>
);

export default Home;
