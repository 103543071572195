import React, { useState, useEffect } from 'react';
import { db, storage } from '../../firebase/firebase';
import { collection, addDoc, getDocs, doc, deleteDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { Box, FormControl, FormLabel, Input, Textarea, Button, VStack, useToast, Heading, InputGroup, InputRightElement, Image, Text, Switch  } from '@chakra-ui/react';
import { serverTimestamp } from 'firebase/firestore';

const EventsList = () => {
  const [events, setEvents] = useState([]);
  const [eventsSnapshot, setEventsSnapshot] = useState([]);
  const toast = useToast();

  useEffect(() => {
    const fetchEvents = async () => {
      const querySnapshot = await getDocs(collection(db, 'events'));
      const eventsData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setEvents(eventsData);
      setEventsSnapshot(eventsData); // Save a snapshot of events
    };

    fetchEvents();
  }, []);

  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(db, 'events', id));
      setEvents(prevEvents => prevEvents.filter(event => event.id !== id));
      toast({
        title: "Event Deleted",
        description: "The event has been successfully deleted.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      setEvents(eventsSnapshot); // Revert to snapshot in case of error
      toast({
        title: "Error deleting event",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const toggleShowInHome = async (id, showInHome) => {
    // Optimistically update the UI
    const newEvents = events.map(event => {
      if (event.id === id) {
        return { ...event, showInHome };
      }
      return event;
    });
    setEvents(newEvents);  // Update the state with the new events array
  
    try {
      await updateDoc(doc(db, 'events', id), {
        showInHome
      });
      toast({
        title: 'Visibility Updated',
        description: `Event "${showInHome ? 'shown' : 'hidden'}" in home page.`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      // Revert back if there's an error
      setEvents(eventsSnapshot);
      toast({
        title: 'Update Failed',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };
  

  const MAX_CHARACTERS = 50;
  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.slice(0, maxLength) + "...";
  };

  return (
    <>
    <Heading as="h1" size="lg">Display or Delete Events </Heading>
    <VStack overflowY="scroll" maxHeight="400px" spacing={4}>
  {events.map(event => (
    <Box key={event.id} p={4} shadow="md" borderWidth="1px" position="relative">
      <VStack spacing={2} align="start">
        <Text fontWeight="bold" fontSize={["md", "lg"]}>{event.title}</Text>
        <Text fontSize={["sm", "md"]} maxW={["250px", "none"]} noOfLines={[2, null]}>{truncateText(event.message, MAX_CHARACTERS)}</Text>
        {event.imageUrl && (
          <Image src={event.imageUrl} alt={event.title} boxSize={["80px", "100px"]} objectFit="cover" />
        )}
      </VStack>
      <VStack spacing={2} align="start" mt={[2, 0]} ml={[0, 4]}>
        <Switch isChecked={event.showInHome} onChange={(e) => toggleShowInHome(event.id, e.target.checked)}>
          Display Event
        </Switch>
        <Button colorScheme="red" size="sm" onClick={() => handleDelete(event.id)}>
          Delete
        </Button>
      </VStack>
    </Box>
  ))}
</VStack>
</>
  );
};




const Admin = () => {
  const [title, setTitle] = useState('');
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');
  const toast = useToast();

  const handleImageUpload = async (file) => {
    const storageRef = ref(storage, `events/${file.name}`);
    const snapshot = await uploadBytes(storageRef, file);
    return getDownloadURL(snapshot.ref);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let uploadedImageUrl = '';

    if (file) {
      uploadedImageUrl = await handleImageUpload(file);
    }

    try {
      await addDoc(collection(db, 'events'), {
        title,
        imageUrl: uploadedImageUrl,
        message,
        createdAt: serverTimestamp(), // Use serverTimestamp for createdAt
      });

      toast({
        title: 'Event added',
        description: 'The event has been successfully added.',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });

      setTitle('');
      setFile(null);
      setMessage('');
    } catch (error) {
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <VStack spacing={8} p={8}>
      <Heading as="h1" size="lg">Add Upcoming Events</Heading>
      <Box as="form" onSubmit={handleSubmit} w="full" maxW="500px">
        <VStack spacing={4}>
          <FormControl id="event-title" isRequired>
            <FormLabel>Event Title</FormLabel>
            <Input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Enter event title"
            />
          </FormControl>
          <FormControl id="event-image">
            <FormLabel>Event Image</FormLabel>
            <InputGroup>
              <Input
                type="file"
                onChange={(e) => setFile(e.target.files[0])}
                accept="image/*"
              />
              {file && <InputRightElement children={file.name} />}
            </InputGroup>
          </FormControl>
          <FormControl id="event-message">
            <FormLabel>Event Message</FormLabel>
            <Textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Enter event message"
            />
          </FormControl>
          <Button type="submit" colorScheme="blue" size="lg" w="full">Add Event</Button>
        </VStack>
      </Box>
      <EventsList/>
    </VStack>
  );
};
export default Admin;
