import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './context/authContext';

function RequireAuth({ children }) {
  const { currentUser } = useAuth();

  if (!currentUser) {
    // User not logged in, redirect to /login
    return <Navigate to="/login" />;
  }

  return children;
}

export default RequireAuth;