import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  VStack,
  Heading,
  Text,
  IconButton,
  useColorModeValue,
  Stack,
  Button,
  Flex,
  Image,
  Link,
  Icon
  
} from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Link as RouterLink } from 'react-router-dom';
import youthImage1 from './youthImage1.jpg';
import youthImage2 from './youthImage2.jpg';
import youthImage3 from './youthImage3.jpg';
import streamsLogo from './streamslogo.png'; 
import {  FaInstagram } from 'react-icons/fa';



const Carousel = ({ images }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slideInterval = useRef();

  useEffect(() => {
    slideInterval.current = setInterval(() => {
      setCurrentSlide(slideIndex => (slideIndex + 1) % images.length);
    }, 5000);

    return () => clearInterval(slideInterval.current);
  }, [images.length]);

  const changeSlide = (direction) => {
    clearInterval(slideInterval.current);
    setCurrentSlide(slideIndex => {
      const newIndex = direction === 'prev' ? slideIndex - 1 : slideIndex + 1;
      return (newIndex + images.length) % images.length;
    });
  };

  return (
    <VStack>
      <Flex width="full" justifyContent="center" alignItems="center" position="relative">
        <IconButton
          aria-label="Previous slide"
          icon={<ChevronLeftIcon />}
          onClick={() => changeSlide('prev')}
          position="absolute"
          left="0"
          zIndex="2"
          variant="solid"
          backgroundColor="rgba(255, 255, 255, 0.6)" // semi-transparent white background
          opacity="0.9" // adjust the opacity as needed
          _hover={{
            bg: "rgba(255, 255, 255, 0.8)", // slightly more opaque on hover
          }}
        />
        <Box
          boxSize="full"
          maxW={{ base: "full", md: "600px", lg: "900px" }}
          borderRadius="md"
          overflow="hidden"
        >
          <img src={images[currentSlide]} alt={`Slide ${currentSlide}`} style={{ width: '100%', display: 'block' }} />
        </Box>
        <IconButton
          aria-label="Next slide"
          icon={<ChevronRightIcon />}
          onClick={() => changeSlide('next')}
          position="absolute"
          right="0"
          zIndex="2"
          variant="solid"
          backgroundColor="rgba(255, 255, 255, 0.6)" // semi-transparent white background
          opacity="0.9" // adjust the opacity as needed
          _hover={{
            bg: "rgba(255, 255, 255, 0.8)", // slightly more opaque on hover
          }}
        />
      </Flex>
    </VStack>
  );
};

const Streams = () => {
  const bg1 = useColorModeValue("white", "blue.900");
  const bg2 = useColorModeValue("black", "balck.900");
  const color = useColorModeValue("black.500", "gray.200");
  const youthImages = [youthImage1, youthImage2, youthImage3];

  return (
    <Stack>
    <Box bg={bg1} p={[4, 8]} boxShadow="xl" borderRadius="lg" align="center" color={color} mb={5} mt={5}>
      <Image 
        src={streamsLogo} 
        alt="FOLCI Streams Logo" 
        boxSize={["120px", "150px"]} // Responsive size
        objectFit="contain"
        mb={4} // Margin bottom for spacing
      />
      <Heading as="h1" size="xl" color="black">WELCOME TO OUR YOUTH CHURCH</Heading>
      <Text fontSize="2xl" fontWeight="bold" color="cyan.400">RAISE, EQUIP, EMPOWER</Text>
      <Text fontSize="lg">Sundays at 12pm. 248 London Road RM7 9EL</Text>
    </Box>
    <Box bg={bg2} p={8} boxShadow="xl" borderRadius="lg" color={color} mb={5} mt={5}>
      <Flex 
        direction={{ base: "column", md: "row" }} // Stack on small screens, horizontal on md screens and above
        align="center" // Centers items vertically in row, horizontally in column
        justify="space-between" // Space between the carousel and the text/button
      >
        <Box width="full" maxW={{ base: "90%", md: "500px" }} overflow="hidden" borderRadius="lg" mb={{ base: 5, md: 0 }}>
          <Carousel images={youthImages} />
        </Box>
        
        <VStack spacing={5} maxW={{ base: "90%", md: "500px" }}>
          <Text>
            Dive into a vibrant community where faith meets action, friendships flourish, and futures are forged with purpose. Join FOLCI Streams for an unforgettable journey of spiritual growth, leadership development, and fun.
          </Text>
          <Button as={RouterLink} to="/contact" colorScheme="orange" size="lg">
            Join Us Now
          </Button>
          <Link mx={2} href="https://www.instagram.com/streamsfolci?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" aria-label="Instagram" _hover={{ color: 'pink.400' }} >
            <Icon as={FaInstagram} />
          </Link>
        </VStack>
      </Flex>
    </Box>

        
    </Stack>
  );
};

export default Streams;
